import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import DOD from '../components/dods';
import Vimeo from '@u-wave/react-vimeo';

// import StateSupport from '../_images/state-support.svg';
// import StudentPopulation from '../_images/student-population.svg';
// import Infrastructure from '../_images/infrastructure.svg';

// import DanBlackKarl from '../_images/dan-black-karl.png';
// import VanHarte from '../_images/van-harte.png';
// import terryGiles from '../_images/terry-giles.png';

import nicholasBegovich from '../_images/nicholas-begovich.jpg';
import danBlack from '../_images/dan-black.jpg';
import josephCervante from '../_images/joseph-cervantes.jpg';
import jeffreyVanHarte from '../_images/jeffrey-van-harte.jpg';
import mayaPatel from '../_images/maya-patel.jpg';

var pageTitle = 'Why I Give';

var pageDesc = 'Cal State Fullerton supporters share their reasons for giving to the “It Takes a Titan” campaign.';

export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>{pageTitle}</title>
      <body className="why-give" />

      <meta name="description" content={pageDesc} />

      {/* Schema.org markup for Google+ */}
      <meta itemprop="name" content={pageTitle} />
      <meta itemprop="description" content={pageDesc} />

      {/* Twitter Card data */}
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDesc} />

      {/* Open Graph data */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDesc} />
    </Helmet>
    <section style={{padding: '150px 0 100px 0',}} className="background-beige">
      <div className="wrap text-center">

        <h1>{pageTitle}</h1>
        <p>Cal State Fullerton supporters share their reasons for giving to the “It Takes a Titan” campaign.</p>

      </div>
    </section>
    <section style={{padding: '50px 0',}} >
      <div className="wrap featured-quotes">
      <blockquote>
          <img src={jeffreyVanHarte} alt="" />
          <div>
            <p>“When you watch students get emotional because they never would have made it to where they are without the opportunities afforded them by the university, it confirms why you give back.” </p>
            <cite>Jeffrey Van Harte ’80 (B.A. business administration-finance) </cite>
          </div>
        </blockquote>

        <blockquote>
          <img src={mayaPatel} alt="" />
          <div>
            <p>“Our partnership with CSUF is mission-aligned, and the projects we’re supporting are focused on providing our community with equal access to opportunity...”</p>
            <cite>Maya Patel, executive chair of the Tarsadia Foundation</cite>
          </div>
        </blockquote>

        <blockquote>
          <img src={danBlack} alt="" />
          <div>
            <p>“I want to help students. It’s rewarding that I play a role in their lives.”</p>
            <cite>Dan Black ’67 (B.S. physics)</cite>
          </div>
        </blockquote>

        <blockquote>
          <img src={josephCervante} alt="" />
          <div>
            <p>“It is my hope that our gift will give the program a better opportunity to compete with other nationally recognized softball programs throughout the U.S.”</p>
            <cite>Joseph Cervantes, senior executive vice president of operations, R.D. Olson Construction</cite>
          </div>
        </blockquote>
      </div>
    </section>
    <section style={{padding: '50px 0',}} className="background-gray">
      <div className="wrap small">

      <blockquote>
          <p>“I can relate to the majority of students at Cal State Fullerton as the first person in my family who went to college. When I was in school and connected with people who had been successful in their life, it gave me great hope that maybe I could do that some day. The support I give to Cal State Fullerton is about trying to pass on that hope to students — so that they can transform their life into anything that they want it to be.”</p>
          <cite>Terry Giles ’70 (B.A. speech communication)</cite>
        </blockquote>

        {/* <blockquote>
          <p>“Tarsadia Foundation is on a mission to unleash human potential and is committed to building a world where economic inclusion and boundless opportunity exists for all. We are committed to building a more inclusive and equitable world for all people, regardless of zip code, gender or color of skin.</p>
          
          <p>“Our partnership with CSUF is mission-aligned, and the projects we’re supporting are focused on providing our community with equal access to opportunity — whether we’re supporting education initiatives or the community’s health and well-being, our end goal is to enable people to become the architects of their own future.”</p>
          <cite>Maya Patel, executive chair of the Tarsadia Foundation</cite>
        </blockquote> */}

        <blockquote>
          <p>“I hope I’m setting an example. To supply the funds to advance our understanding of the world — that is amazing joy … real joy.”</p>
          <cite>Nicholas Begovich (1921-2020)</cite>
        </blockquote>

        <blockquote>
          <p>“We are proud to help the College of Education offer innovative, high-quality and inclusive educational opportunities to future, pre-service and in-service teachers in our community.”</p>
          <cite>Bill Cheney, president and CEO, SchoolsFirst Federal Credit Union</cite>
        </blockquote>

        <blockquote>
          <p>“I’ve had a wonderful career as a faculty member at CSUF, and I wanted to pay it forward in a meaningful way. Our music students and alumni go far when they share their skills and passions to enrich everyone’s life experiences across campus, within our communities and throughout the world.”</p>
          <cite>Marc Dickey, professor emeritus of music</cite>
        </blockquote>

        <blockquote>
          <p>“Helping Titans is so important in putting them on the road to success, so they can better our community and world.”</p>
          <cite>Karl Freels ’12, ’17 (B.A., M.A. history)</cite>
        </blockquote>

        

        <blockquote>
          <p>“I was working on my M.P.A. and interning with the Costa Mesa City Manager’s Office. M.P.A. Director Alan Saltzstein called and said CSUF would like to nominate me for the new Presidential Management Intern program. Seven months later, I started with the Office of the Secretary of Defense. I would go on to hold positions in the Office of Management and Budget, U.S. Senate Appropriations and Budget Committees, Lockheed Martin, and the recreational boating and sportfishing trade associations. I even got to lead the National Oceanic and Atmospheric Administration for almost a year. I never forget that Cal State Fullerton gave me my start and made this possible.”</p>
          <cite>Scott Gudes ’78 (M.P.A.)</cite>
        </blockquote>

        <blockquote>
          <p>“I gave to the campaign to broaden opportunities for students beyond the limitations of the state budget and to enhance their contributions to the campus and the world outside.”</p>
          <cite>Mike Horn, professor emeritus of biological science</cite>
        </blockquote>

        <blockquote>
          <p>“We supported the campaign to help CSUF’s School of Engineering and Computer Science develop students who are capable of identifying and successfully solving present and emerging challenges in our society. We give because we remember how pivotal it is to receive help from entities outside your family. We hope our support motivates students to successfully complete their studies as it demonstrates that there are others who believe in them, too.”</p>
          <cite>Darrell ’85 and Vicky Jodoin</cite>
        </blockquote>

        <blockquote>
          <p>“I’m extremely pleased to contribute to the 'It Takes a Titan' campaign at Cal State Fullerton knowing that my donation will be used to support the Fullerton Arboretum, a world-class botanical collection and greenspace sanctuary. My gift will help ensure that the arboretum will continue to support student success, cutting-edge research and real-world conservation. As a retired forester, I can think of no better way to pay it forward to the next generation so that they may undertake meaningful environmental learning experiences.”</p>
          <cite>Francis Colwell and Sandy Koizumi</cite>
        </blockquote>

        <blockquote>
          <p>“At first, I began giving to honor my husband’s memory and his 23 years of devoted work at Cal State Fullerton. I wanted to keep his legacy alive in the areas of campus that meant the most to him. I support areas that he worked in or that he supported when he was on campus, namely the Fullerton Arboretum and the cello studies area in the School of Music. </p>
          
          <p>“Now, six years after his passing, I continue to give because of the associations I have made on campus in these areas — the people who are able to help students or provide the facilities that allow students to pursue their studies and interests. I also contribute to volunteer organizations that support the performing arts in the Department of Theatre and Dance and the School of Music, as they fund scholarships and experiential learning opportunities. My own enjoyment of the arts on campus has become an additional part of my motivation for giving.”</p>
          <cite>Jill Kurti Norman</cite>
        </blockquote>

        <blockquote>
          <p>“We supported the campaign to provide financial aid and mentoring to CSUF engineering and computer science students struggling to enter the professional ranks of the ECS community. We have been contacted by so many students who have thanked us for our support and for playing a role in helping them achieve their goals.”</p>
          <cite>William ’76, ’79, ’83 and Paula Purpura ’77, ’83</cite>
        </blockquote>

        <blockquote>
          <p>“Earning a college degree was an expectation, but how to pay for my college education was an entirely different issue. FAFSA was key to my ability to attend college. With every grant, loan, work study and scholarship, I attended USC and transferred to CSUF after the loss of my mother to cancer. CSUF welcomed me and supported my goals. Financial needs were greater after the loss of my mother, and I received numerous grants, loans and scholarships. After retiring from working in the public school system, I am in a position to support other students through scholarships. That’s why I give! I will never forget when a scholarship made the difference between books and supplies and buying Top Ramen. Donor support and scholarships took me from being a young person on food stamps to being someone in the wonderful position to help support others.”</p>
          <cite>Jennifer ’87 and Mark Shepard</cite>
        </blockquote>

        <blockquote>
          <p>“I have worked at Cal State Fullerton for more than 35 years, and it has been a great experience. The support of my fellow faculty members has been fantastic and working with young information systems and decision sciences students is phenomenal. I have always wanted to give back to the university in whatever way I could. This year, I wanted to continue with my humble efforts by giving scholarships directly to our students to encourage them in their careers.”</p>
          <cite>Ram Singhania, professor emeritus of information systems and decision sciences</cite>
        </blockquote>

        <blockquote>
          <p>“This investment will help students from disadvantaged backgrounds attain college degrees that lead to high-wage careers in our county’s growing sectors, including engineering and computer science. It’s a true win-win opportunity to create lasting positive results.” </p>
          <cite>Allan Staff, president, Bank of America Orange County</cite>
        </blockquote>

        <blockquote>
          <p>“My mom, Maxine Yvonne Mitchell, was a teacher who was very active in both encouraging and supporting education her whole life. Even after retiring from her teaching career, she continued participating in the California Retired Teacher Association. Her strong belief in making education available for anyone was why she set aside money to donate to Cal State Fullerton, which helped her further her career.”</p>
          <cite>Ross Stovall ’76, trustee, Mitchell Family Trust</cite>
        </blockquote>

        <blockquote>
          <p>“There are many reasons I donate to the institution that provided me such a rewarding career. Perhaps foremost, I fully believe that this is a truly transformative university. Our student body, most of whom are first-generation, represents the future of not only our local community but the world at large. We create tomorrow each and every day with a committed faculty and staff. By building programs that will last several lifetimes, we will leave a legacy that expands our world. My wife, Sandy DeAngelis, and I are total believers in the future of Cal State Fullerton.”</p>
          <cite>Ed Trotter, professor emeritus of communications</cite>
        </blockquote>

        <blockquote>
          <p>“I created the Gayton Scholarship at Cal State Fullerton in honor of my parents, Ann and Fred Gayton. My father, Fred, was an electrical engineer and encouraged others to study in the STEM fields. This scholarship endowment continues our legacy of championing higher education and supporting women and underrepresented students in the STEM fields.”</p>
          <cite>Beth Gayton Bangert ’71 (B.A. history)</cite>
        </blockquote>

        

      </div>
    </section>
    <DOD />
  </Layout>
  </React.Fragment>
);
